/** @format */

import React, { Fragment } from 'react';
import projects from '../projectData';

const SingleProject = () => (
	<Fragment>
		{projects &&
			projects.map((project, i) => (
				<div className='post' key={i}>
					<img className='thumbnail' src={project.image} alt='projectImage' />
					<div className='post-preview'>
						<h6 className='post-title'>{project.title}</h6>
						<p className='post-intro'>{project.details}</p>
						<a target='_blank' rel='noopener noreferrer' href={project.link}>
							View Project
						</a>
					</div>
				</div>
			))}
	</Fragment>
);

export default SingleProject;
