/** @format */

import React, { Fragment } from "react";
import "./App.css";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Alerts from "./components/Alerts";
import { init } from "@emailjs/browser";
init(process.env.REACT_APP_USER_ID);

function App() {
  return (
    <Fragment>
      <Alerts />
      <Hero />
      <About />
      <Projects />
      <Contact />
    </Fragment>
  );
}

export default App;
