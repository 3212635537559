/** @format */

import React from "react";
import resume from "../images/resume.pdf";
import image from "../images/socialImage.webp";

const About = () => (
  <section className="s2">
    <div className="main-container">
      <div className="about-wrapper">
        <div className="about-me">
          <h4>More about me</h4>
          <p>
            A Frontend Engineer with a flair for converting high-fidelity design
            prototypes into working, performant and accessible applications.
          </p>
          <p>
            I am a positive, enthusiastic and competent frontend engineer who,
            over the years, has built up a diverse range of skills, qualities
            and attributes.
          </p>
          <p>
            I have extensive experience working both alone and as part of a team
            on often time-sensitive, challenging projects that require
            outstanding creative and technical capabilities and the ability to
            ensure all work is optimized across a wide range of platforms.
          </p>
          <p>
            I take my work seriously and this means I always ensure my skills
            are kept up to date within this rapidly changing industry.
          </p>
          <p>
            Finally, I always put the commercial needs the organizations I work
            with at the forefront of everything I do, and also ensure that the
            work I carry out is always of first-class standard.
          </p>
          <hr />
          <h4>TOP EXPERTISE</h4>
          <p>
            Although I'm a fullstack developer, but for a few years now, I've
            worked more on the frontend than the backend:
            <a target="_blank" rel="noopener noreferrer" href={resume}>
              Download Resume
            </a>
          </p>
          <div id="skills">
            <ul>
              <li>JavaScript</li>
              <li>React</li>
              <li>Apollo/Graphql</li>
              <li>Testing Libraries</li>
              <li>Jest</li>
              <li>Node</li>
            </ul>
            <ul>
              <li>TypeScript</li>
              <li>MongoDB</li>
              <li>Firebase</li>
              <li>AWS</li>
              <li>HTML/CSS</li>
              <li>CSS Frameworks</li>
            </ul>
          </div>
        </div>
        <div className="social-links">
          <img id="social_img" src={image} alt="" />
          <h3>Find me on Twitter & LinkedIn</h3>
          <a
            href="https://www.twitter.com/iamjosephdara"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter: @iamjosephdara
          </a>
          <br />
          <a
            href="https://www.linkedin.com/in/iamjosephdaramola/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn: @iamjosephdaramola
          </a>
          <br />
          <a
            href="https://github.com/iamjosephdaramola"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub: @iamjosephdaramola
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default About;
