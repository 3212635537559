/** @format */
import newsGrid from './images/newsGrid.png';
import luxury from './images/luxury.png';
import phone from './images/phoneStore.png';
import courses from './images/courses.png';
import contactKeeper from './images/contactKeeper.png';
import ostello from './images/ostello.png';

export default [
	{
		image: ostello,
		title: 'Ostello Company',
		details:
			'A revolutionary platform working towards making education easy and accessible to all in India.',
		link: 'https://www.ostello.co.in/',
	},
	{
		image: courses,
		title: 'Find Dev Courses',
		details:
			'A platform where you can get dev courses. Built with React, Agolia API and Plurasight JSON data',
		link: 'https://playground-bootcamp.netlify.com/',
	},
	{
		image: phone,
		title: 'Phone Store',
		details:
			'A react phone e-commerce project that has an add-to-cart functionality implemented. This is just a frontend application.',
		link: 'https://phones-catalogue.netlify.app',
	},
	{
		image: newsGrid,
		title: 'News Grid',
		details:
			'A static UI for a news website built with HTML and CSS. No JavaScript nor Js framework, purely HTML and CSS.',
		link: 'https://blissful-leakey-eb3dc0.netlify.com',
	},
	{
		image: luxury,
		title: 'Beach Resort',
		details:
			'An hotel application built with the React library. It has some filters implemented that can be used to filter the available rooms.',
		link: 'https://luxury-is-cool.netlify.com/',
	},

	{
		image: contactKeeper,
		title: 'Contact Keeper',
		details:
			'This is a fullstack application built with React and Nodejs. Authenticated users can store contact details of folks on MongoDB.',
		link: 'https://polar-brook-71577.herokuapp.com',
	},
];
