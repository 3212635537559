/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AlertState from './context/alert/alertState';
import ApiState from './context/api/apiState';

ReactDOM.render(
	<React.StrictMode>
		<AlertState>
			<ApiState>
				<App />
			</ApiState>
		</AlertState>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.register();
