/** @format */

import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import ApiContext from './apiContext';
import ApiReducer from './apiReducer';
import AlertContext from '../alert/alertContext';
import {
	SET_LOADING,
	CONTACT,
	CONTACT_ERROR,
	CLEAR_ERROR,
	END_LOADING,
} from '../types';

const ApiState = (props) => {
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	const initialState = {
		loading: false,
		error: null,
	};

	const [state, dispatch] = useReducer(ApiReducer, initialState);

	const contactUs = async (data) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		try {
			setLoading();
			const res = await axios.post(`/api/contactUs`, data, config);
			const msg = res.data.msg;
			setAlert(msg, 'success', 3000);

			dispatch({
				type: CONTACT,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.data) {
				console.log(err.response.data.msg);
				setAlert('Under construction...', 'danger', 3000);
			}
			endLoading();
			if (err.response) {
				dispatch({
					type: CONTACT_ERROR,
					payload: err.response.data.msg,
				});
			}
		}
	};

	const setLoading = () => dispatch({ type: SET_LOADING });

	const endLoading = () => dispatch({ type: END_LOADING });

	const clearErrors = () => dispatch({ type: CLEAR_ERROR });

	return (
		<ApiContext.Provider
			value={{
				...state,
				clearErrors,
				contactUs,
			}}
		>
			{props.children}
		</ApiContext.Provider>
	);
};

export default ApiState;
