/** @format */
import {
	SET_LOADING,
	CONTACT,
	CONTACT_ERROR,
	CLEAR_ERROR,
	END_LOADING,
} from '../types';

export default (state, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: true,
			};
		case END_LOADING:
			return {
				...state,
				loading: false,
			};
		case CONTACT:
			return {
				...state,
				loading: false,
			};
		case CONTACT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
};
