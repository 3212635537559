/** @format */

const light = {
	'--mainColor': '#eaeaea',
	'--secondaryColor': '#fff',
	'--mainText': 'black',
	'--borderColor': '#c1c1c1',
	'--secondaryText': '#4b5156',
	'--themeDotBorder': '#24292e',
	'--previewBg': 'rgba(251, 249, 243, 0.8)',
	'--previewShadow': '#f0ead6',
	'--buttonColor': 'black',
};

const blue = {
	'--mainColor': '#15202b',
	'--secondaryColor': '#192734',

	'--borderColor': '#164d56',
	'--mainText': '#fff',
	'--secondaryText': '#eeeeee',
	'--themeDotBorder': '#fff',
	'--previewBg': 'rgb(25, 39, 52, 0.8)',
	'--previewShadow': '#111921',
	'--buttonColor': '#17a2b8',
};

const green = {
	'--mainColor': '#606b56',
	'--secondaryColor': '#515a48',
	'--borderColor': '#161914',
	'--mainText': '#fff',
	'--secondaryText': '#eeeeee',
	'--themeDotBorder': '#fff',
	'--previewBg': 'rgb(81, 90, 72, 0.8)',
	'--previewShadow': '#2a2f25',
	'--buttonColor': '#669966',
};

const purple = {
	'--mainColor': '#46344e',
	'--secondaryColor': '#382a3f',
	'--borderColor': '#1d1520',
	'--mainText': '#fff',
	'--secondaryText': '#eeeeee',
	'--themeDotBorder': '#fff',
	'--previewBg': 'rgb(29, 21, 32, 0.8)',
	'--previewShadow': '#2b202f',
	'--buttonColor': '#8534a3',
};

export default { green, purple, blue, light };
