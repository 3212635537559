/** @format */

import React from "react";
import SingleProject from "./SingleProject";

const Projects = () => (
  <section className="s1" style={{ display: "none" }}>
    <div className="main-container">
      <h3 style={{ textAlign: "center" }}>Some of my past projects</h3>
      <div className="post-wrapper">
        <SingleProject />
      </div>
    </div>
  </section>
);

export default Projects;
