/** @format */

import React, { useEffect } from "react";
import image from "../images/myPic.jpg";
import themes from "../themes";

const Hero = () => {
  useEffect(() => {
    let savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      changeTheme(savedTheme);
    } else {
      changeTheme("light");
    }
    // eslint-disable-next-line
  }, []);

  // override the CSS VARIABLES
  const setTheme = (theme) => {
    Object.keys(theme).map((key) => {
      const value = theme[key];
      return document.documentElement.style.setProperty(key, value);
    });
  };

  const changeTheme = (mode) => {
    if (mode === "light") {
      setTheme(themes.light);
    }
    if (mode === "blue") {
      setTheme(themes.blue);
    }
    if (mode === "green") {
      setTheme(themes.green);
    }
    if (mode === "purple") {
      setTheme(themes.purple);
    }

    localStorage.setItem("theme", mode);
  };
  return (
    <section className="s1">
      <div className="main-container">
        <div className="greeting-wrapper">
          <h1>Hi, I'm Joseph Daramola</h1>
        </div>
        <div className="intro-wrapper">
          <div className="nav-wrapper">
            <div className="dots-wrapper">
              <div id="dot-1" className="browser-dot"></div>
              <div id="dot-2" className="browser-dot"></div>
              <div id="dot-3" className="browser-dot"></div>
            </div>
            <ul id="navigation">
              <li>
                <a href="#contact-form">Contact</a>
              </li>
            </ul>
          </div>
          <div className="left-column">
            <img id="profile-pic" src={image} alt="IamJoseph" />
            <h5 style={{ textAlign: "center", lineHeight: 0 }}>
              Personalize Theme
            </h5>
            <div className="theme-options-wrapper">
              <div
                onClick={() => changeTheme("light")}
                id="light-mode"
                className="theme-dot"
              ></div>
              <div
                onClick={() => changeTheme("blue")}
                id="blue-mode"
                className="theme-dot"
              ></div>
              <div
                onClick={() => changeTheme("green")}
                id="green-mode"
                className="theme-dot"
              ></div>
              <div
                onClick={() => changeTheme("purple")}
                id="purple-mode"
                className="theme-dot"
              ></div>
            </div>
            <p id="settings-note">
              Theme settings will be saved for <br />
              your next visit
            </p>
          </div>
          <div className="right-column">
            <div id="preview-shadow">
              <div id="preview">
                <div id="corner-tl" className="corner"></div>
                <div id="corner-tr" className="corner"></div>
                <h3>What I Do</h3>
                <p>
                  I'm a frontend engineer with primary focus on React,
                  JavaScript, Node, and Graphql.
                </p>
                <div id="corner-bl" className="corner"></div>
                <div id="corner-br" className="corner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
