/** @format */

import React, { useContext, useState } from "react";
import AlertContext from "../context/alert/alertContext";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setAlert("Please, fill in all fields", "danger", 3000);
    } else {
      setLoading(true);
      const templateParams = { ...details };
      await emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            setAlert("Text received! I'll respond shortly", "success", 3000);
          },
          (err) => {
            setAlert(
              "Oops! Something went wrong. Please try again",
              "danger",
              3000
            );

            console.log("FAILED...", err);
          }
        );
      setLoading(false);

      setDetails({ name: "", email: "", message: "" });
    }
  };
  return (
    <section className="s2">
      <div className="main-container">
        <h3 style={{ textAlign: "center" }}>Get In Touch</h3>
        <form onSubmit={onSubmit} id="contact-form">
          <label>Name</label>
          <input
            style={{ textTransform: "capitalize" }}
            type="text"
            className="input-field"
            name="name"
            value={name}
            onChange={onChange}
          />
          <label>Email</label>
          <input
            type="email"
            className="input-field"
            name="email"
            value={email}
            onChange={onChange}
          />
          <label>Leave A Message</label>
          <textarea
            className="input-field"
            name="message"
            value={message}
            onChange={onChange}
          ></textarea>
          {loading ? (
            <button className="submit-btn">Loading...</button>
          ) : (
            <input type="submit" className="submit-btn" value="Send" />
          )}
        </form>
      </div>
    </section>
  );
};

export default Contact;
