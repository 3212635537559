/** @format */

import React, { useContext } from 'react';
import AlertContext from '../context/alert/alertContext';
import { BsInfoCircleFill } from 'react-icons/bs';

const Alerts = () => {
	const alertContext = useContext(AlertContext);
	return (
		alertContext.alerts.length > 0 &&
		alertContext.alerts.map((alert) => (
			<div key={alert.id} className={`custom-alert custom-alert-${alert.type}`}>
				<p className='mr-2 mt-2'>
					<BsInfoCircleFill />
				</p>{' '}
				<p className=''>{alert.msg}</p>
			</div>
		))
	);
};

export default Alerts;
